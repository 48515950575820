<template>
  <div>
    <!--begin::Employees-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Training Details</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools">
            <div class="my-2 mr-3">
              <a
                @click="redirectToBack()"
                class="btn btn-primary font-weight-bolder"
              >
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Training:</b> 
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.name }} 
                </v-col>
                <v-col cols="12" sm="2">
                  <b>Category: </b>
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.category_name }} 
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Description:</b> 
                </v-col>
                <v-col cols="12" sm="4">
                  {{ training.description }} 
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Price per Employee: </b>
                </v-col>
                <v-col cols="12" sm="4">
                  ${{ training.price }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="2">
                  <b>Video:</b>
                </v-col>
                <v-col cols="12" sm="4">
                  <!-- a @click="downloadVideo()" download>
                    Download Video
                  </a> -->
                  <template>
                      <Media 
                        :kind="'video'"
                        :controls="true"
                        v-bind:src="training.videoPath"
                        style="width:100%" 
                      >
                      </Media>
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Enrolled Employees</h3>
        </div>
      </div>
      <div class="card-body">
        <v-row no-gutters align="center">
          <v-col cols="12" sm="3">
            <b> Employee Name </b>
          </v-col>
          <v-col cols="12" sm="2">
            <b> Start Date </b>
          </v-col>
          <v-col cols="12" sm="2">
            <b> End Date </b>
          </v-col>
          <v-col cols="12" sm="2">
            <b> Training Progress </b>
          </v-col>
          <v-col cols="12" class="text-center" sm="3">
            <b> Update Date </b>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          align="center"
          v-for="employee in enrolled_employees"
          v-bind:key="employee.user_id"
        >
          <v-col cols="12" sm="3">
            <v-checkbox
              v-model="employee.is_completed"
              style="padding:0px; height: 20px; margin-top:3px;"
              :label="employee.first_name + ' ' +employee.last_name"
              @click="completeTraining(employee)"
              required
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="5" class="row" v-if="datepicker_id == employee.user_id">
            <date-range-picker class="pr-6" style="width:100%" v-model="dateRange">
              <!--header slot-->
              <div slot="header" slot-scope="header" class="slot pl-3 pt-3">
                <h3>Update Dates</h3> 
                <span v-if="header.in_selection"> - in selection</span>
              </div>
            </date-range-picker>
          </v-col>
          <v-col cols="12" sm="5" class="row" v-else>
            <v-col cols="12" sm="5">
              {{ employee.training_start_date ? employee.training_start_date : "mm-dd-YYYY" }}
            </v-col>
            <v-col cols="12" sm="5">
              {{ employee.training_end_date ? employee.training_end_date : "mm-dd-YYYY" }}
            </v-col>
          </v-col>
          <v-col cols="12" sm="2">
            {{ employee.training_progress ? employee.training_progress : "0" }}%
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <v-tooltip top v-if="employee.user_id == datepicker_id">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  small
                  v-on="on"
                  @click="saveDates(employee.user_id)"
                >
                  <v-icon color="#B5B5C3">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Save Date</span>
            </v-tooltip>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  small
                  v-on="on"
                  @click="editDates(employee)"
                >
                  <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Update Date</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="card card-custom gutter-b" v-if="auth_user.is_company_admin == 1">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Enrol New Employee</h3>
        </div>
      </div>
      <div class="card-body">
        <v-row align="center" no-gutters>
          <v-autocomplete
            v-model="selected_employee"
            :items="company_user_list"
            item-text="value"
            item-value="key"
            outlined
            dense
            chips
            multiple
            label="Select Employee"
          ></v-autocomplete>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="2">
            <b>Total Price:</b> 
          </v-col>
          <v-col cols="12" sm="4">
            $ &nbsp; {{ total_price }} 
          </v-col>
        </v-row>
        <v-container fluid class="card-footer pl-3 pt-3">
          <v-btn
            v-if="!disable_btn"
            color="success"
            class="mr-4"
            ref="kt_store_submit"
            @click="enrollEmployee"
          >
          <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
            Enroll Now
          </v-btn>

          <v-btn
            v-else
            color="success"
            class="mr-4"
            ref="kt_store_submit"
            @click="enrollEmployee"
            disabled
          >
          <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
            Enroll Now
          </v-btn>
        </v-container>
      </div>
    </div>
    <!--end::Employees-->

    <!-- Dialogue -->
    <div class="text-center">
      <v-dialog v-model="dialog" persistent width="650">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Payment
          </v-card-title>
          <v-card-text>
            <div class="sub_total_div row py-4 mt-4">
                <div class="col-12">
                  <h4>Payable Amount: ${{total_price}}</h4>
                </div>
            </div>
            <stripe-element-card
              class=""
              ref="elementRef"
              :pk="pulishableKey"
              :hidePostalCode="true"
              @token="tokenCreated"
            />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn @click="closePaymentModal">
                Cancel
              </v-btn>
              <v-btn color="green lighten-2" dark @click="submit" v-if="!disable_payment_btn" >
                <b-spinner v-if="disable_payment_btn" label="Spinning"></b-spinner>
                Make Payment
              </v-btn>
              <v-btn color="green lighten-2" dark @click="submit" disabled style="background-color: #81C784 !important; border-color:#81C784 !important; color: #FFF !important;"
              v-else>
                <b-spinner v-if="disable_payment_btn" label="Spinning"></b-spinner>
                Make Payment
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Dialogue -->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import {
  STORE_ENROLL_EMPLOYEES,
  UPDATE_TRAINING_DATES,
  MARK_TRAINING_COMPLETED
} from "@/core/services/store/training.module";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Media from '@dongido/vue-viaudio';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

export default {
  name: "EnrollTraining",
  vuetify: new Vuetify(),
  components: {
    Media,
    StripeElementCard
  },
  data() {
    this.pulishableKey = "pk_test_51ISr2sKH8A8bPL9DaFII2XmGLOlNHDBiqacsIjxcv634CFyBLNV36GHGoqn7wg6iVoOyjmxBMiAdckD5NJeJNVri00ZRW5eXCD";
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)
    return {
      auth_user : {},
      back_url: process.env.VUE_APP_ACBACK_URL.slice(0, -5),
      training: {
        name: "",
        category_name: "",
        path: "",
        description: "",
        price: 0,
        videoPath: ""
      },
      company_user_list: [],
      enrolled_employees: [],
      selected_employee: "",
      total_price: 0,
      datepicker_id: 0,
      dateRange: {startDate, endDate},
      disable_btn: false,
      disable_payment_btn: false,
      dialog: false,
    };
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
  watch: {
    selected_employee: function() {
      this.changeTotalPrice();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getAuthUser"]),
    ...mapGetters(["companyUsers"])
  },
  mounted() {
    let context = this;
    context.auth_user = context.getAuthUser;

    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Training Details" }]);
    context.getTraining();
    context.getCompanyUsers();
    context.getEnrolledEmployees();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    closePaymentModal() {
      let context = this;
      context.dialog= false;
    },
    submit () {
      let context = this;
      this.$refs.elementRef.submit();
    },
    tokenCreated (token) {
      // handle the token
      // send it to your server
      let context = this;
      context.disable_payment_btn = true;

      axios({
        method: "post",
        url: "enroll-employees",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: { 
                "selected_employees": context.selected_employee,
                "training_id" : this.$route.params.id,
                "total_amount": context.total_price,
                "amount_per_employee": context.training.price,
                "token_id": token.id,
                "card_id": token.card.id,
                "token_obj": token
              }
        })
      .then(function(result) {
        if (result.data.status == 0) {
            Swal.fire("Error", result.data.message, "", "error");
        }else{
            Swal.fire("Success", result.data.message, "success");
            context.closePaymentModal();
            context.disable_payment_btn = false;
            context.getCompanyUsers();
            context.getEnrolledEmployees();
            context.selected_employee = "";
        }
      });
    },
    downloadVideo() {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/download-training-video/" +
        this.$route.params.id;
      window.open(url);
    },
    completeTraining(employee) {
      if(employee.is_completed) {
        Swal.fire({
          title: "Do you want to Complete this training for " + 
          employee.first_name + " " + employee.last_name + "?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes! Complete',
          denyButtonText: "No",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
             let request_parameter = {
                employee_id: employee.user_id,
                training_id: this.$route.params.id,
                is_completed: employee.is_completed
              };
            this.$store.dispatch(MARK_TRAINING_COMPLETED,request_parameter)
              .then(function() {
               
            });
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        });
      } else {
        Swal.fire({
          title: "Do you want to InComplete this training for " + 
          employee.first_name + " " + employee.last_name + "?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes! Mark InComplete',
          denyButtonText: "No",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            let request_parameter = {
                employee_id: employee.user_id,
                training_id: this.$route.params.id,
                is_completed: employee.is_completed
              };
              this.$store.dispatch(MARK_TRAINING_COMPLETED,request_parameter)
                .then(function() {
                  Swal.fire('Completed!', 'Training marked incompleted successfully.', 'success');
              });
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        });
      }
    },
    editDates(employee) {
      let context = this;
      if(context.datepicker_id == employee.user_id) {
        context.datepicker_id = 0; //hide datepicker
        let startDate = new Date();
        let endDate = new Date();
        context.dateRange.startDate = startDate;
        context.dateRange.endDate = endDate;
      } else {
        context.datepicker_id = employee.user_id; //show datepicker
        context.dateRange.startDate = employee.training_start_date ? new Date(employee.training_start_date) : new Date();
        context.dateRange.endDate = employee.training_end_date ? new Date(employee.training_end_date) : new Date();
      }
    },
    saveDates(emp_id) {
      let context = this;
      let request_parameter = {
        employee_id: emp_id,
        training_id: this.$route.params.id,
        training_start_date: context.dateRange.startDate,
        training_end_date: context.dateRange.endDate,
      };
      this.$store.dispatch(UPDATE_TRAINING_DATES,request_parameter)
        .then(function() {
           Swal.fire("Success", "Date updated Successfully!", "success");
           context.datepicker_id = 0;
           context.getEnrolledEmployees();
        });
    },
    getEnrolledEmployees() {
      let context =this;
      axios({
        method: "get",
        url: "get-enrolled-employees",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: { training_id: this.$route.params.id }
      }).then(function(result) {
        if (result.data.enrolled_employees) {
          context.enrolled_employees = result.data.enrolled_employees;
        }
      });
    },
    changeTotalPrice() {
      let context =this;
      context.total_price = context.training.price * context.selected_employee.length;
    },
    enrollEmployee() {
      let context = this;
      if(context.selected_employee == ""){
        Swal.fire("Error", "Please select employee!", "error");
      } else {
          context.disable_btn = true;
          axios({
            method: "post",
            url: "check-enrolled-employees",
            baseURL: process.env.VUE_APP_ACBACK_URL,
            data: { "selected_employees": context.selected_employee,
                    "training_id" : this.$route.params.id
                  }
            })
          .then(function(result) {
            if (result.data.status == 0) {
                Swal.fire("Error", result.data.message, "", "error");
            }else{
                if(context.total_price == 0){
                    context.enrollEmployeesWithoutPayment();
                }else{
                    context.disable_btn = false;
                    context.dialog = true;
                }
            }
          });
      }
    },
    enrollEmployeesWithoutPayment() {

      let context = this;

      axios({
        method: "post",
        url: "enroll-employees-without-payment",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        data: { 
                "selected_employees": context.selected_employee,
                "training_id" : this.$route.params.id,
                "total_amount": context.total_price,
                "amount_per_employee": context.training.price
              }
        })
      .then(function(result) {
        context.disable_btn = false;
        if (result.data.status == 0) {
            Swal.fire("Error", result.data.message, "", "error");
        }else{
            Swal.fire("Success", result.data.message, "success");
            context.getCompanyUsers();
            context.getEnrolledEmployees();
            context.selected_employee = "";
        }
      });
    },
    getCompanyUsers() {
      let context = this;
      axios({
        method: "get",
        url: "get-training-users",
        baseURL: process.env.VUE_APP_ACBACK_URL,
        params: {training_id : this.$route.params.id}
      }).then(function(result) {
        if (result.data.training_users) {
          context.company_user_list = [];
          result.data.training_users.forEach(user => {
            context.company_user_list.push(
              {key: user.id, value : user.first_name + " " + user.last_name}
            );
          });
        }
      });
    },
    redirectToBack() {
      var context = this;
      context.$router.go(-1); //go back to list
    },
    getTraining() {
      let context = this;
      axios({
        method: "get",
        url: "training/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.training.name) {
            context.training = result.data.training;
          } else {
            Swal.fire(result.data.message, "", "error");
          }
        },
        function() {
          Swal.fire("Error", "Training fetch error!", "error");
        }
      );
    }
  }
};
</script>
